import React from 'react';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import createRoutes from './routes';
import reducers from './reducers/Foo';
import { Header, Footer } from 'trv-tds-react';
import './styles/app.scss';

let store = createStore(reducers);

function App() {
  const routes = createRoutes();

  return (
    <Provider store={store}>
      <Header href="/" appName={process.env.REACT_APP_APPLICATION_TITLE}>
        <Header.Nav></Header.Nav>
      </Header>
      <main>
        <h1>{process.env.REACT_APP_APPLICATION_TITLE}</h1>
        {routes}
      </main>
      <Footer>
        <Footer.Copy />
        <Footer.Social />
      </Footer>
    </Provider>
  );
}

export default App;
