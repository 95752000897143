export const extractTokenFromQueryString = (queryString,tokenStringIdentifier) => {
  
  let token ='';  

  if ( queryString===undefined || tokenStringIdentifier===undefined){
    token ='';
  }
  else if (queryString === null || queryString === '' || tokenStringIdentifier === null || tokenStringIdentifier === '' ){
    token ='';
  }
  else 
  {
    if (queryString.includes(tokenStringIdentifier)){
      token = queryString.split(tokenStringIdentifier)[1] ;  
    }      
  }                
  return token;
};

export default { extractTokenFromQueryString };
