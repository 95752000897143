import Axios from 'axios';
require('dotenv').config();

class UTMService {  

  static deTokenize(data){    
      
    return new Promise((resolve, reject) => {   
      let url = '/api/usertoken/validate';
      Axios.put(url, data).then(response => {                     
        resolve(response);
      }).catch(err => {                
        reject(err);
      });
    });

  }
}

export default UTMService;
