import './style.scss';
import React from 'react';
import { Component } from 'react';
require('dotenv').config();


class SessionTimeout extends Component{

  render(){
    
    return( <div className="medium-container">
      <h1 className="margin-top-xx-large tds-h1--small">We&rsquo;re sorry.</h1>
      <p className="margin-top-xx-large tds-large">For your security, your session has expired and your information has not been saved.</p>      
      <hr className="content-divider margin-top-xx-large shorten-divider" />
      <p className="margin-top-xx-large tds-heading">If the problem persists, please contact the {process.env.REACT_APP_RMIS_CALL_US_CONTACT}  at <a href={'tel:+' + process.env.REACT_APP_RMIS_CALL_US_NUM}>{process.env.REACT_APP_RMIS_CALL_US_NUM}</a>.</p>
    </div>);  
  } 
}
export default SessionTimeout;
