import React from 'react';
export class ExternalRedirect extends React.Component {
  constructor(props) {
    super();
    this.state = { ...props };
  }
  componentWillMount() {
    window.location.replace(this.props.to);
  }
  render() {
    return (<section>Redirecting...</section>);
  }
}

export default ExternalRedirect;
