
export const validateUTMServiceResponse = (response) => {

  var errorMessage = '';

  if (response.operationStatus.statusCode && response.operationStatus.statusCode === '700') {
    errorMessage = 'The requested resource has expired';
  }
  if (response.operationStatus.statusCode && response.operationStatus.statusCode === '604') {
    errorMessage = 'Data not found';
  }
  return errorMessage;
};



export default {validateUTMServiceResponse};
