import Axios from 'axios';
require('dotenv').config();

class EnterpriseUserTokenService {
  static generateToken(data) {
    return new Promise((resolve, reject) => {
      let url = '/api/enterpriseUserToken/generate';
 	    Axios.post(url, data).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  }
}

export default EnterpriseUserTokenService;
