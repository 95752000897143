import './style.scss';
import React from 'react';
import { Component } from 'react';
import  validator  from '../../utils/validator';

require('dotenv').config();


class MaxAttemptsReached  extends Component{

  

  render(){
    
    // let IAEName = this.props.location.state.IAEDetails.IAENAme;
    // let IAEContactDetails = this.props.location.state.IAEDetails.IAEPhone ;
    //Access IAE Name & IAE Phone number from this .props.location.state
        
     

    
    let IAEName =this.getIAEName ();
    let IAEPhoneNum = this.getIAEPhone();
    let IAEDetailsEmpty = this.AreIAEDetailsEmpty(IAEName,IAEPhoneNum);
    
    return( <div className="medium-container">
      <h1 className="margin-top-xx-large tds-h1--small">We&rsquo;re sorry.</h1>
      <p className="margin-top-xx-large tds-large">You have exceeded the number of attempts to verify your identity.</p>
      <hr className="content-divider margin-top-xx-large shorten-divider" />  

      { IAEDetailsEmpty ?
        <p className=" margin-top-xx-large tds-heading">To complete your account setup, please contact the {process.env.REACT_APP_RMIS_CALL_US_CONTACT}  at <a href={'tel:+' + process.env.REACT_APP_RMIS_CALL_US_NUM}>{process.env.REACT_APP_RMIS_CALL_US_NUM}</a>.</p>                      
        :<p className=" margin-top-xx-large tds-heading">To complete your account setup, please contact { IAEName } at <a href={'tel:+' + IAEPhoneNum }>{IAEPhoneNum}</a> or the {process.env.REACT_APP_RMIS_CALL_US_CONTACT}  at <a href={'tel:+' + process.env.REACT_APP_RMIS_CALL_US_NUM}>{process.env.REACT_APP_RMIS_CALL_US_NUM}</a>.</p>             
      }                    
    </div>);  
    
  }
  
  getIAEName (data){    
    let retVal ='';
    let queryStringObject = this.props.location ;
    if (queryStringObject !== ''|| queryStringObject!==null || queryStringObject!==undefined ){
      let queryStringObjectState = queryStringObject.state;
      if (queryStringObjectState && queryStringObjectState.iaePhoneNum ){              
        retVal = queryStringObjectState.iaeName ;
      }    
    }    
    return retVal ;
  }

  getIAEPhone (data){       
    let retVal ='';
    let queryStringObject = this.props.location ;
    if (queryStringObject !== ''|| queryStringObject!==null || queryStringObject!==undefined ){
      let queryStringObjectState = queryStringObject.state;
      if (queryStringObjectState && queryStringObjectState.iaePhoneNum ){

        retVal = queryStringObjectState.iaePhoneNum ;
      }
    }
    return retVal ;
  }

  AreIAEDetailsEmpty (name,phone){
        
    return (validator.isNullOrEmpty(name) || validator.isNullOrEmpty(phone)) ;
    
  }

 
}
export default MaxAttemptsReached;
