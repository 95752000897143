import React from 'react';
import { OneTimePinWidget } from '@trv/ent-otp-widget-pkg';
import userManagementService from '../../components/api/userManagement/index.jsx';
import EnterpriseUserTokenService from '../../components/api/enterpriseToken/index.js';
import ExternalRedirect from '../../components/common/externalRedirect';
import tokenExtractor from '../../utils/tokenExtractor';
import validateUTMServiceResponse from '../../components/validation/validation.js';
import  AutoTimeout from '../../components/common/autotimeout.jsx';
require('dotenv').config();

class EcarmaOtp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      error: '',
      generateOTPRouter: '',
      passwordPagePayload: {},
      render: false,
      verifyOTPRouter: ''
    };
  }
  componentDidMount() {

    //Once component mounts - do the following 
    //Get Token from URL
    //Create payload for Detokenization Service
    // Call Detokenization service -> to get email id 
    this.genereteEmailFromToken();


  }

  genereteEmailFromToken() {

    //recieveTokenFrom SFMC QueryString  
    let tokenFromSFMC = this.getQueryStringFromURL();
    //if token is null/invalid - redirect the user to error page         
    if (tokenFromSFMC===''){
      this.props.history.push('/notfound');
    }
    else {

      //if Token from SFMC is valid then call Token service for email
      let payloadForDetoknization = this.createPayloadForDetokenizationService(tokenFromSFMC);
      this.callUTMServiceToDetokenize(payloadForDetoknization);
    }
    
  }
  constructPayloadForPasswordPage() {
    //Expiration date for Password page
    var expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 2);

    let passwordPagePayload = {
      ExpirationDate: expirationDate,
      LinkCreationSource: 'ELogin',
      LoginCredential: this.state.data.RmisId
    };

    return passwordPagePayload;
  }
  createPayloadForDetokenizationService(tokenFromSFMC) {

    let inputDataforDetoknization = {
      encryptedSignature: tokenFromSFMC
    };

    return inputDataforDetoknization;

  }
  getQueryStringFromURL() {

    let encryptedString = '';
    let tokenIdentifierFromSFMC = '?token=';
    let queryStringValuefromSFMC = this.props.location.search;
    //Extract Token from Query String
    encryptedString = tokenExtractor.extractTokenFromQueryString(queryStringValuefromSFMC, tokenIdentifierFromSFMC);
    return encryptedString;
  }
  callUTMServiceToDetokenize(inputDataforDetoknization) {

    userManagementService.deTokenize(inputDataforDetoknization).then(response => {

      if (response && response.data && response.data.data && response.status === 200) {
        //saving the incoming data 
        const self = this;
        self.setState({
          data: response.data.data,
          generateOTPRouter: '/api/otp/generate',
          redirectBase : process.env.REACT_APP_REDIRECT_BASE,
          referenceRouter: '/api/reference/errormessages',
          render: true,
          verifyOTPRouter: '/api/otp/validate'
        });

      }
      else {
        const errorfromUTM = validateUTMServiceResponse(response);
        this.setState({
          error: errorfromUTM
        });
        //When response code is other than 200
        this.props.history.push('/technicalError');
      }

    }).catch(err => {
      this.setState({
        error: err.message
      });
      //Error from UserToken Service - technical error
      this.props.history.push('/technicalError');
    });
  }
  
  //onVerifyOtpSuccess
  onVerifyOtpSuccess() {
   
    //Invoke Generate Token API 
    //Build payload for password page...
    this.generateEnterpriseToken();

  }
  generateEnterpriseToken() {
    let passwordPagePayload = this.constructPayloadForPasswordPage();
    EnterpriseUserTokenService.generateToken(passwordPagePayload).then(userTokenReponse => {
      if (userTokenReponse && userTokenReponse.data) {
        //Encode Token to invoke password page
        var enCodedUrlToken = encodeURIComponent(userTokenReponse.data.CreateEnterpriseUserTokenResult.UserToken);
        //Build Password page URL with TOKEN...
        const passwordPageURL = this.state.redirectBase + enCodedUrlToken;
        //console.info('passord ulr is '+passwordPageURL);
        //create url from token and encode
        this.setState({ redirect: true,redirectUrl: passwordPageURL });
        
      } else {     
        
        this.props.history.push('/technicalError');      
      }
    }).catch(err => {      
      this.props.history.push('/technicalError');
    });

  }

  onFailureInWidget(errorCode,erroMessage){
    
    //When max attempts reached for a request 
    if (errorCode === 'MAX_ATTEMPTS_REACHED'){
      //passing Data object to error handling page so that IAE information can be displayed 
      this.props.history.push('/maxAttemptsReached',this.createDataObjectForErrorhandling());
    }
    //in all other scenarios 
    //errorCode === "TECHNICAL_ERROR"
    else{
      this.props.history.push('/technicalError');
    }    
  }
  createDataObjectForErrorhandling (){    
    let retVal = this.state.data === undefined ? '':this.state.data ;
    return retVal;
  }

  render() {

    let renderContainer = false;
    if (this.state.render) {
      renderContainer = (<div>
        <OneTimePinWidget firstName={this.state.data.firstName}
          lastName={this.state.data.lastName}
          input={this.state.data.input}
          inputType={this.state.data.inputType}
          correlationId={this.state.data.correlationId}
          appName={this.state.data.appName}
          generateOTPRouter={this.state.generateOTPRouter}
          verifyOTPRouter={this.state.verifyOTPRouter}
          referenceRouter={this.state.referenceRouter}
          //Callback on Verify OTP success in Widget
          onVerifyOtpSuccess={this.onVerifyOtpSuccess.bind(this)}
          onFailureInWidget ={this.onFailureInWidget.bind(this)}
        />
      </div>);

      //redirect logic start
      const { redirect } = this.state;
      if (redirect) {
        return <ExternalRedirect to={this.state.redirectUrl} />;
      }
      //redirect logic end
    }

    return (renderContainer);
  }

}
const AutoTimeoutEcarmaOtp = AutoTimeout(EcarmaOtp);
export default AutoTimeoutEcarmaOtp;
