import React from 'react';
import { BrowserRouter as Router, Route,Switch } from 'react-router-dom';
import { Redirect} from 'react-router';
import EcarmaOtp from './pages/oneTimePin';
import PageUnavailable from './components/error/pageUnavailabe';
import TechnicalError from './components/error/technicalError';
import SessionTimeout from './components/error/sessionTimeout';
import MaxAttemptsReached from './components/error/maxAttemptesReached';

const createRoutes = () => (
  <div>
    <Router>
      <Switch>                       
        <Route exact path="/oneTimePin" component={ EcarmaOtp } />
        <Route exact path="/notFound" component={PageUnavailable} />  
        <Route exact path="/technicalError" component={TechnicalError} />
        <Route exact path="/timeOut" component={SessionTimeout} />
        <Route exact path="/maxAttemptsReached" component={MaxAttemptsReached} />
        <Route exact path="/" component={() => (<Redirect to="/notFound"/>)} />      
        
      </Switch>
      <div className="tds-container-fluid">
      </div>
      
    </Router>
  </div>
);

export default createRoutes;
